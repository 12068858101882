import apiCall from 'libs/utils/api/apiCall';
import { ApiResponse, SharedErrors } from 'libs/utils/api/types';
import { Order } from './orders/types';

// GET /cart
export const submitFiles = (orderNumber, authToken): ApiResponse<Order, SharedErrors> =>
  apiCall.get<Order, SharedErrors>({
    url: `/orders/submit_files/${orderNumber}?auth_token=${authToken}`,
    unscoped: true,
  });

// GET /cart
export const getOrder = (location: string): ApiResponse<Order, SharedErrors> =>
  apiCall.get<Order, SharedErrors>({ url: `/cart?location=${location}` });

// PATCH /cart
export const postCheckout = () =>
  apiCall.patch<Order, SharedErrors>({ url: '/cart', data: { checkout: '' }, unscoped: true });
