import React, { useContext, useEffect, useState } from 'react';
import Button from 'styleguide/components/Button/Button';
import UserContext from 'contexts/UserContextContainer/UserContext';
import { acknowledgeAddressValidation } from 'api/account/settings';
import { Status } from 'libs/utils/api/types';
import Toast from 'utils/toast';
import Grid from 'styleguide/components/Grid/Grid';
import loadable from '@loadable/component';
import retry from 'utils/retry';

export const Modal = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ 'styleguide/components/Modal/Modal')),
);

const AddressValidationModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { currentUser } = useContext(UserContext);

  useEffect(() => {
    if (currentUser && !currentUser.addressValidationAcknowledged) {
      setIsModalOpen(true);
    }
  }, [currentUser]);

  const handleAcknowledgeUpdate = () => {
    acknowledgeAddressValidation(currentUser)
      .then(res => {
        if (res.status !== Status.Ok) {
          Toast.error('Error acknowledging address validation');
        }
      })
      .finally(() => setIsModalOpen(false));
  };

  return (
    isModalOpen && (
      <Modal className="!max-w-2xl" shown={isModalOpen} disableClickOutside hideScroll>
        <Grid.Container className="pb-[10px]">
          <Grid>
            <Grid.Row>
              <Grid.Col className="flex flex-col items-start justify-center" sm={12}>
                <div data-cy="forgotFilesModalTitle" className="heading-bold-xs">
                  Address Validation Feature
                </div>
                <div data-cy="forgotFilesModalContent" className="paragraph-desktop my-6 text-left">
                  Welcome back! We&apos;ve made some improvements to our shipments. We now require all
                  shipping addresses to be verified to ensure accurate deliveries. Please take a moment to
                  review and verify your saved addresses in your Account addresses page. Thank you!
                </div>
                <div className="flex flex-col md:flex-row md:justify-end">
                  <Button
                    type="button"
                    color="blue"
                    className="md:!h-[47px] -md:mb-6 !px-5"
                    onClick={handleAcknowledgeUpdate}
                    size="md"
                    dataCy="acknowledgeAddressValidationBtn"
                  >
                    Yes, I will update my addresses in my Account addresses page
                  </Button>
                </div>
              </Grid.Col>
            </Grid.Row>
          </Grid>
        </Grid.Container>
      </Modal>
    )
  );
};

export default AddressValidationModal;
