import { useState, useEffect, useRef } from 'react';
import { LIGHT_HOUSE_DELAY } from 'app/constants';

const useDelayedScriptLoader = (initCallback: () => void = () => {}) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const scriptDidInitRef = useRef(scriptLoaded);

  const removeEventListeners = (fn: () => void) => {
    document.removeEventListener('scroll', fn);
    document.removeEventListener('mousemove', fn);
    document.removeEventListener('touchstart', fn);
  };

  const initScript = () => {
    if (scriptDidInitRef.current) {
      return;
    }
    setScriptLoaded(true);
    scriptDidInitRef.current = true;

    initCallback();

    removeEventListeners(initScript);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!scriptDidInitRef.current) {
        initScript();
      }
    }, LIGHT_HOUSE_DELAY);

    document.addEventListener('scroll', initScript);
    document.addEventListener('mousemove', initScript);
    document.addEventListener('touchstart', initScript);

    return () => {
      removeEventListeners(initScript);
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    scriptDidInitRef.current = scriptLoaded;
  }, [scriptLoaded]);

  return scriptLoaded;
};

export default useDelayedScriptLoader;
