import { Helmet } from 'react-helmet-async';
import * as React from 'react';
import useDelayedScriptLoader from 'utils/hooks/useDelayedScriptLoader';

interface Props {
  zendeskWidgetKey: string;
}
const ZendeskChatWidget = ({ zendeskWidgetKey }: Props) => {
  const loadScript = useDelayedScriptLoader();

  return (
    loadScript && (
      <Helmet>
        <script
          async
          id="ze-snippet"
          src={`https://static.zdassets.com/ekr/snippet.js?key=${zendeskWidgetKey}`}
        />
      </Helmet>
    )
  );
};

export default ZendeskChatWidget;
