import { getOrder } from 'api/cartPage';
import { Status } from 'libs/utils/api/types';
import {
  Order,
  OrderTotalsHash,
  Upload,
  Adjustment,
  LineItemResponse,
  Shipment,
  Promotion,
} from 'api/orders/types';
import { populateOrder, removeLineItem, updateLineItem } from 'api/orders/orders';
import { KeyVal } from 'libs/utils/common-types';
import { doApplyPromoCodeRequest } from 'api/simplyShip';
import { OrderActions } from './reducer';
import { Comment } from 'app/api/admin/orders/types';
import { cartPromoCode } from 'api/gtm';

export type OrderDispatchType = React.Dispatch<OrderActions>;

export const updateRiskiness = (
  dispatch: React.Dispatch<Extract<OrderActions, { type: 'updateRiskiness' }>>,
  isRisky: boolean,
) => {
  dispatch({ type: 'updateRiskiness', payload: { isRisky } });
};

export const setStatus = (
  dispatch: React.Dispatch<Extract<OrderActions, { type: 'setStatus' }>>,
  status: Extract<OrderActions, { type: 'setStatus' }>['payload']['status'],
) => {
  dispatch({ type: 'setStatus', payload: { status } });
};

export const setPaymentState = (
  dispatch: React.Dispatch<Extract<OrderActions, { type: 'setPaymentState' }>>,
  paymentState: string,
) => {
  dispatch({ type: 'setPaymentState', payload: { paymentState } });
};

export const updateTotals = (
  dispatch: React.Dispatch<Extract<OrderActions, { type: 'updateTotals' }>>,
  orderTotals: OrderTotalsHash,
) => {
  dispatch({ type: 'updateTotals', payload: { orderTotals } });
};

export const setOrder = (
  dispatch: React.Dispatch<Extract<OrderActions, { type: 'setOrder' }>>,
  order: Order,
) => {
  dispatch({ type: 'setOrder', payload: { order } });
};

export const setReferenceNumber = (
  dispatch: React.Dispatch<Extract<OrderActions, { type: 'setReferenceNumber' }>>,
  referenceNumber: string,
) => {
  dispatch({ type: 'setReferenceNumber', payload: { referenceNumber } });
};

export const getCurrentOrder = (dispatch: OrderDispatchType, location: string) => {
  dispatch({ type: 'setStatus', payload: { status: 'loading' } });
  getOrder(location).then(res => {
    if (res.status === Status.Ok) {
      dispatch({ type: 'setOrder', payload: { order: res.payload } });
    }
    dispatch({ type: 'setStatus', payload: { status: 'loaded' } });
  });
};

export const removeUploadToFileVersion = (
  dispatch: React.Dispatch<Extract<OrderActions, { type: 'removeUploadToFileVersion' }>>,
  id: number,
) => {
  dispatch({ type: 'removeUploadToFileVersion', payload: { id } });
};

export const addUploadToFileVersion = (
  dispatch: React.Dispatch<Extract<OrderActions, { type: 'addUploadToFileVersion' }>>,
  fileVersionId: number,
  upload: Upload,
) => {
  dispatch({ type: 'addUploadToFileVersion', payload: { fileVersionId, upload } });
};

export const removeUploadToProofApproval = (
  dispatch: React.Dispatch<Extract<OrderActions, { type: 'removeUploadToProofApproval' }>>,
  id: number,
) => {
  dispatch({ type: 'removeUploadToProofApproval', payload: { id } });
};

export const addUploadToProofApproval = (
  dispatch: React.Dispatch<Extract<OrderActions, { type: 'addUploadToProofApproval' }>>,
  proofApprovalId: number,
  upload: Upload,
) => {
  dispatch({ type: 'addUploadToProofApproval', payload: { proofApprovalId, upload } });
};

export const updateAdjustments = (
  dispatch: React.Dispatch<Extract<OrderActions, { type: 'updateAdjustments' }>>,
  adjustments: Adjustment[],
) => {
  dispatch({ type: 'updateAdjustments', payload: { adjustments } });
};

export const updateAdjustment = (
  dispatch: React.Dispatch<Extract<OrderActions, { type: 'updateAdjustment' }>>,
  adjustment: Adjustment,
) => {
  dispatch({ type: 'updateAdjustment', payload: { adjustment } });
};

const processProductionItems = (dispatch: OrderDispatchType, response: LineItemResponse) => {
  dispatch({ type: 'updateProductionItems', payload: { productionItems: response.productionItems } });
  dispatch({
    type: 'updateProductionItemGroups',
    payload: { productionItemGroups: response.productionItemGroups },
  });
};

export const processLineItemCreated = (dispatch: OrderDispatchType, response: LineItemResponse) => {
  dispatch({ type: 'addLineItem', payload: { lineItem: response.lineItem } });
  processProductionItems(dispatch, response);
  updateAdjustments(dispatch, response.adjustments);
  dispatch({ type: 'updateTotals', payload: { orderTotals: response.orderTotals } });
};

export const processLineItemUpdated = (dispatch: OrderDispatchType, response: LineItemResponse) => {
  dispatch({ type: 'updateLineItem', payload: { id: response.lineItem.id, lineItem: response.lineItem } });
  processProductionItems(dispatch, response);
  updateAdjustments(dispatch, response.adjustments);
  dispatch({ type: 'updateTotals', payload: { orderTotals: response.orderTotals } });
};

export const processLineItemDestroyed = (dispatch: OrderDispatchType, response: LineItemResponse) => {
  dispatch({ type: 'removeLineItem', payload: { id: response.lineItem.id } });
  processProductionItems(dispatch, response);
  updateAdjustments(dispatch, response.adjustments);
  dispatch({ type: 'updateTotals', payload: { orderTotals: response.orderTotals } });
};

export const doAddLineItem = (
  dispatch: React.Dispatch<Extract<OrderActions, { type: 'setStatus' }>>,
  values: KeyVal,
  alias: string,
): Promise<boolean> =>
  new Promise(resolve => {
    dispatch({ type: 'setStatus', payload: { status: 'loading' } });
    populateOrder(values, alias).then(res => {
      dispatch({ type: 'setStatus', payload: { status: 'loaded' } });
      if (res.status === Status.Ok) {
        processLineItemCreated(dispatch, res.payload);
        resolve(true);
      } else {
        resolve(false);
      }
    });
  });

export const doRemoveLineItem = (dispatch: OrderDispatchType, id: number): Promise<void> =>
  new Promise(resolve => {
    dispatch({ type: 'setStatus', payload: { status: 'loading' } });
    removeLineItem(id).then(res => {
      if (res.status === Status.Ok) {
        dispatch({ type: 'removeLineItem', payload: { id } });
        dispatch({ type: 'updateTotals', payload: { orderTotals: res.payload } });
      }
      dispatch({ type: 'setStatus', payload: { status: 'loaded' } });
      resolve();
    });
  });

export const doUpdateLineItem = (
  dispatch: OrderDispatchType,
  orderNumber,
  id: number,
  quoterJson: KeyVal,
): Promise<void> =>
  new Promise(resolve => {
    dispatch({ type: 'setStatus', payload: { status: 'loading' } });

    updateLineItem(id, quoterJson).then(res => {
      if (res.status === Status.Ok) {
        processLineItemUpdated(dispatch, res.payload);
      }
      dispatch({ type: 'setStatus', payload: { status: 'loaded' } });
      resolve();
    });
  });

export const updatePromotions = (dispatch: OrderDispatchType, promotions: Promotion[]) => {
  dispatch({ type: 'updatePromotions', payload: { promotions } });
};

const handlePromoCode = (dispatch: OrderDispatchType, promoCode: string, shouldRemovePromotion = false) => {
  dispatch({ type: 'setStatus', payload: { status: 'loading' } });
  doApplyPromoCodeRequest(promoCode, shouldRemovePromotion).then(res => {
    if (res.status === Status.Ok) {
      cartPromoCode({
        promo_code: promoCode,
        action: 'Applied',
        discount_amount: res.payload.orderTotals.promotionTotal,
        outcome: +res.payload.orderTotals.promotionTotal < 0 ? 'Discount Applied' : 'No Change',
      });
      dispatch({ type: 'updateTotals', payload: { orderTotals: res.payload.orderTotals } });
      updatePromotions(dispatch, res.payload.promotions);
      dispatch({ type: 'updatePromotions', payload: { promotions: res.payload.promotions } });
    } else if (res.status === Status.ClientError) {
      cartPromoCode({
        promo_code: promoCode,
        error_message: res.payload.message,
        outcome: 'Error',
      });
    }
    dispatch({ type: 'setStatus', payload: { status: 'loaded' } });
  });
};

export const applyPromoCode = (dispatch: OrderDispatchType, promoCode: string) => {
  handlePromoCode(dispatch, promoCode);
};

export const removePromoCode = (dispatch: OrderDispatchType, promoCode: string) => {
  handlePromoCode(dispatch, promoCode, true);
};

export const updateShipment = (
  dispatch: React.Dispatch<Extract<OrderActions, { type: 'updateShipment' }>>,
  shipment: Shipment,
) => {
  dispatch({ type: 'updateShipment', payload: { shipment } });
};

export const updateProductionDays = (
  dispatch: React.Dispatch<Extract<OrderActions, { type: 'updateProductionDays' }>>,
  productionDays: number,
) => {
  dispatch({ type: 'updateProductionDays', payload: { productionDays } });
};

export const updateComments = (
  dispatch: React.Dispatch<Extract<OrderActions, { type: 'updateComments' }>>,
  comments: Comment[],
) => {
  dispatch({ type: 'updateComments', payload: { comments } });
};
