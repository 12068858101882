import * as React from 'react';
import toasts from 'utils/toast';
import { TypeOptions } from 'react-toastify';

interface Props {
  type?: TypeOptions;
  message: string;
}
const Banner = ({ message, type = 'info' }: Props) => {
  const [isShown, setIsShown] = React.useState<boolean>(false);

  React.useEffect(() => {
    setIsShown(true);
  }, []);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{!isShown && toasts.banner(message, type)}</>;
};

export default Banner;
