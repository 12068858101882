import * as React from 'react';
import AppContext from './AppContext';
import { ReactOnRailsContext, Store, Options } from './types';
import { addSeconds, getDate, getHours, getMonth, getYear, set, subSeconds } from 'date-fns';
import { format, getTimezoneOffset, toZonedTime } from 'date-fns-tz';

interface Props {
  store: Store;
  env: ReactOnRailsContext;
  options: Options;
  children: React.ReactNode;
}

const timeZone = 'America/Los_Angeles';

const AppContextContainer = ({ store, env, options, children }: Props) => {
  const isServerDst = (date: Date) => {
    // Inspired by https://github.com/moment/luxon/blob/3.0.3/src/datetime.js#L1235-L1244

    const offset = getTimezoneOffset(timeZone, date);
    const january = new Date(date.getTime());
    january.setMonth(0);
    january.setDate(0);
    const januaryOffset = getTimezoneOffset(timeZone, january);

    const may = new Date(date.getTime());
    may.setMonth(4);
    const mayOffset = getTimezoneOffset(timeZone, may);
    return offset > januaryOffset || offset > mayOffset;
  };

  const serverOffset = (date: Date) => (isServerDst(date) ? env.utcOffsetSecondsDst : env.utcOffsetSeconds);
  const convertTimezoneKeepTime = (date: string | Date) => {
    if (!date) {
      return null;
    }

    const newDate: Date = typeof date === 'string' ? new Date(date) : date;
    const timezoneDate = set(toZonedTime(date, timeZone), {
      hours: getHours(newDate),
      date: getDate(newDate),
      month: getMonth(newDate),
      year: getYear(newDate),
    });
    const newDateString = format(timezoneDate, 'yyyy-MM-dd HH:mm:ssXXX', { timeZone });

    return newDateString;
  };

  // converts a date with same time zone to new hours due to the timezone offset
  const keepTimezoneConvertTime = (date: string | Date) => {
    if (!date) {
      return null;
    }
    let newDate: Date = typeof date === 'string' ? new Date(date) : date;

    const localOffset = newDate.getTimezoneOffset() * 60; // seconds
    const difference = serverOffset(newDate) + localOffset;
    if (difference > 0) {
      newDate = subSeconds(newDate, difference);
    } else {
      newDate = addSeconds(newDate, difference);
    }

    return newDate;
  };

  const contextValue = React.useMemo(
    () => ({
      store,
      options,
      env,
      convertTimezoneKeepTime,
      keepTimezoneConvertTime,
    }),
    [store, options, env],
  );

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};

export default AppContextContainer;
