import * as React from 'react';
import ScreenSizeContext, { ScreenSize } from 'contexts/ScreenSizeContext/ScreenSizeContext';

const sm = 530;
const lg = 1024;

const smMq = `only screen and (max-width: ${(sm - 1).toString()}px)`;

const mdMq = `only screen and (min-width: ${sm.toString()}px) and (max-width: ${(lg - 1).toString()}px)`;

const lgMq = `only screen and (min-width: ${lg.toString()}px)`;

interface MediaQuery {
  query: string;
  size: ScreenSize;
}

interface Props {
  initialScreenSize?: ScreenSize;
  children: React.ReactNode;
}

const ScreenSizeContextContainer = ({ initialScreenSize, children }: Props) => {
  const mediaQueries: MediaQuery[] = [
    { query: smMq, size: 'SM' },
    { query: mdMq, size: 'MD' },
    { query: lgMq, size: 'LG' },
  ];

  const [screenSize, setScreenSize] = React.useState<ScreenSize>(initialScreenSize);

  React.useEffect(() => {
    const listeners = mediaQueries.map(({ query, size }) => ({ mqList: window.matchMedia(query), size }));

    const idx = listeners.findIndex(({ mqList }) => mqList.matches);
    const size = idx !== -1 ? listeners[idx].size : 'LG';

    setScreenSize(size);
  }, []);

  React.useEffect(() => {
    const listeners = mediaQueries.map(({ query, size }) => {
      const mqList = window.matchMedia(query);
      const listener = (event: MediaQueryListEvent) => {
        if (event.matches) {
          setScreenSize(size);
        }
      };
      mqList.addListener(listener);
      return { mqList, listener };
    });

    return () => {
      listeners.forEach(({ mqList, listener }) => mqList.removeListener(listener));
    };
  }, [mediaQueries]);

  const contextValue = React.useMemo(
    () => ({
      screenSize,
    }),
    [screenSize],
  );

  return <ScreenSizeContext.Provider value={contextValue}>{children}</ScreenSizeContext.Provider>;
};

export default ScreenSizeContextContainer;
